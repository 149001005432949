@import "../../../static/styles/colors.scss";

.header-box {
    background: $white;
    position: fixed;
    z-index: 100;
    width: 100%;
    transition: 0.3s all linear;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.10);
    .header-title {
        align-self: center;
        font-size: 20px;
        font-weight: 600;
        line-height: 22px;
        @media (min-width: 524px) and (max-width: 767px) {
            font-size: 12px;
            font-weight: 600;
            line-height: 14px;
            height: 28px;
            display: flex;
            align-items: center;
            text-align: end;
        }
        @media (max-width: 523px) {
            display: none;
        }
    }
}

