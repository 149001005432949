@import '../../../static/styles/colors.scss';

.swiper-service {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: 1180px;
    gap: 24px;
    button {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        padding: 0;
        svg {
            color: rgba(0, 0, 0, 0.4);
        }
        &:hover svg {
            color: $blue;
        }
        @media (max-width: 600px) {
            display: none;
        }
    }
    .swiper {
        flex-shrink: 2;
        width: 100%;
        height: 100%;
        //padding-top: 24px;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    //.swiper-slide img {
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //  border-radius: 16px;
    //}
    //.swiper-slide-visible {
    //    box-shadow: 0 1px 40px $grey_shadow-block !important;
    //}
    //.swiper-slide-active,
    //.swiper-slide-prev,
    //.swiper-slide-next {
    //    box-shadow: 0 4px 40px $grey_shadow-block !important;
    //}
}
