@import "src/static/styles/colors";

.refillBlock{
  max-width: 656px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  .title{
    color: #002D55;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }

  .inputContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    .infoCard{
      width: 100%;
      height: 56px;
      display: flex;
      align-items: center;
      max-width: 320px;
      padding: 12px;
      background: #fff;
      border: 1px solid #ECECEF;
      border-radius: 4px;
      box-sizing: border-box;


      .infoText{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: rgba(0,0,0, 0.4);
      }
    }
  }

  .loadFileContainer{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;

    .loadFileBtn{
      height: 48px;
      min-width: 48px;
      padding: 12px;
      background: $yellow;
      border: none;
      border-radius: 4px;
    }

    .loadFileText{
      color: #000;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;

    }
  }


  .nextBtn{
    height: 40px;
    background: $yellow;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    color: #fff !important;
  }

}

.calcHelperText{
  max-width: 732px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: $blue;
  text-align: left;
}

.idLinkContainer{
  display: flex;
  align-items: center;
  gap: 8px;

  .idLinkText{
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    color: $blue;
    text-align: left;
  }
}