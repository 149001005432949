@import "src/static/styles/colors";

.createAccBlock{
  max-width: 656px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  .title{
    color: #002D55;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }

  .descriptionRefill{
    color: #002D55;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;

    .subDescriptionRefill{
      font-weight: 500;
    }
  }

  .inputContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .btnContainer{
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;

    .btn{
      height: 40px;
      background: $yellow;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      color: #fff !important;
    }
  }
}

.calcHelperText{
  max-width: 732px;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  color: $blue;
  text-align: left;
}
