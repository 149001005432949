.homeBLock{
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow: hidden;

  .video{
    min-width: 100vw;
    min-height: 100vh;
    filter: brightness(0.5);
  }

  .ContentContainer{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    position: absolute;
    z-index: 1;
    background: rgba(0,0,0, 0.5);

    .logoWrap{
      width: 200px;
      height: 200px;

      @media screen and (max-width: 600px) {
        width: 160px;
        height: 160px;
      }

      .logoImg{
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .mainTitle{
      font-size: 60px;
      font-weight: 400;
      line-height: 76px;
      color: #fff;
      text-align: center;

      @media screen and (max-width: 600px) {
        font-size: 32px;
        line-height: 32px;
      }
    }

    .partnersContainer{
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      gap: 16px;

      .partnerCard{
        width: 268px;
        height: 117px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 6px;
        background: rgba(253, 186, 47, 0.1);
        border: 1px solid #FDBA2F;
        box-sizing: border-box;
        border-radius: 6px;

        @media screen and (max-width: 600px) {
          width: 171px;
          height: 171px;
        }
      }

      .partnerTitle{
        font-size: 18px;
        font-weight: 500;
        line-height: 18px;
        color: #FDBA2F;

        @media screen and (max-width: 600px) {
          font-size: 16px;
          font-weight: 500;
          line-height: 20px;
        }
      }
    }
  }
}
