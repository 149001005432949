@import '../../../../static/styles/colors.scss';
.contacts {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;

    .contact {
        padding: 0;
        width: 356px;
        height: 356px;
        display: flex;
        flex-direction: column;
        align-items: center;
        //margin: 0 10px;
        position: relative;
        overflow: hidden;
        border-radius: 16px;

        &--info {
            padding: 12px;
            text-align: center;
            position: absolute;
            bottom: 0;
            background: rgba(255, 255, 255, 0.8);
            width: 100%;
            min-height: 96px;
        }

        &--image {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        p {
            margin-top: 4px;
            color: $blue;
        }

        a {
            line-height: 20px;
        }

        h2 {
            color: $blue;
        }
    }
    @media screen and (max-width: 600px) {
        .contact {
            width: 328px;
            height: 328px;
        }
    }
}
