@import '../../../static/styles/colors';

.slider {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;

    p {
        color: $blue;
    }
    .slide_layout {
        margin-top: 24px;
        position: relative;
        // overflow: 'hidden',
        max-width: 1116px;
        width: 100%;
        height: 501px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
    }
}
