.tiktok_pay_modal {
    min-width: 469px;
    max-width: 469px;
    min-height: 426px;
    text-align: center;
    width: calc(100vw - 48px);
    display: flex;
    flex-direction: column;
    position: relative;
    &__toggle {
        width: 100%;
        background: transparent !important;
        border-radius: 8px !important;
        padding: 4px !important;
        margin-bottom: 24px;

        .MuiButtonBase-root {
            display: flex;
            text-transform: none !important;
            border: none !important;
            font-size: 16px;
            font-weight: 500;
            border-radius: 8px !important;
            padding: 16px 32px !important;
        }
    }
    &__contact_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        margin-top: 24px;
        &__text {
            font-size: 14px;
            font-weight: 500;
            opacity: 0.65;
            margin-bottom: 4px;
        }
        &__text_bold {
            color: #0f2c52;
            text-decoration: none;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 336px;
}
.loader__content {
    &__message {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}

