@import '../../../static/styles/colors';
.paginator {
    display: flex;
    justify-content: center;
    align-items: center;
    &--toggle {
        height: 32px;
        background: transparent !important;
        border-radius: 8px !important;
        padding: 0 !important;

        .MuiButtonBase-root {
            width: 32px;
            height: 32px;
            border-radius: 50% !important;
            text-transform: none;
            border: none;
            //font-family: Gilroy, serif;
            font-size: 14px;
            font-weight: 400;
            color: $blue !important;
        }
        .Mui-selected {
            background: $yellow !important;
            padding: 8px 4px !important;
            color: $white !important;
        }
    }

    &--controller {
        height: 32px;
        width: 32px !important;
        padding: 0 !important;
        color: $blue !important;
        background-color: transparent !important;
        border-radius: 50% !important;
        min-width: 0 !important;
        margin: 0 14px !important;
        svg {
            transition: 0.3s color ease;
            color: $blue !important;
        }
    }
    &--controller:hover {
        svg {
            color: $yellow !important;
        }
    }
}
