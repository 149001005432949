@import '../../../static/styles/colors.scss';

.swiper-buttons {
    width: 100%;
    max-width: 100vw;
    display: flex;
    margin-top: 28px;

    button {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        padding: 0;

        svg {
            color: $yellow;
        }
    }

    .swiper {
        flex-shrink: 2;
        width: 100%;
    }

    .button {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 18px 32px;
        white-space: nowrap;

        width: 100%;
        background: $yellow;

        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        border-radius: 8px;
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        /* identical to box height */

        text-align: center;

        /* TDS/Белый */

        color: #ffffff;
    }

    .swiper-slide {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        //@media (max-width: 700px) {
        //    width: 328px;
        //    height: 166px;
        //}
    }

    //.swiper-slide img {
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //  border-radius: 16px;
    //}
}
