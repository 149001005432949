@import "src/static/styles/colors";

.createAccBlock{
  max-width: 656px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  .title{
    color: #002D55;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }

  .inputContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
  }

  .nextBtn{
    height: 40px;
    background: $yellow;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    color: #fff !important;
  }

  .checkBoxContainer{
    display: flex;
    align-items: center;
  }

}

.metaInputContainer{
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: center;

  .metaHelperText{
    max-width: 45%;
  }
}

@media screen and (max-width: 600px) {
  .metaInputContainer{
    flex-direction: column;
    align-items: flex-start !important;

    .metaHelperText{
      max-width: 100%;
    }
  }
}

.checkBoxText{
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;

  .link{
    color: #0000EE;
    text-decoration: none;
  }
}