@import '../../static/styles/colors.scss';
.page {
    width: 100%;
    //margin-top: 68px;
    //min-height: calc(100vh - 140px);
    //height: 100vh;
    padding-top: 68px;
    background: $white;
}

.section {
    width: 100%;
    &-white {
        background-color: $white;
    }
    &-grey {
        background-color: $grey;
    }
}

.section-wrapper {
    max-width: 1116px;
    margin: 0 auto;
    padding: 48px 0;
    gap: 24px;
    display: flex;
    flex-direction: column;
    @media (max-width: 767px) {
        padding: 24px;
    }

}
.tiktok-presentation {
    //padding: 0;
    padding: 48px 0;
    display: flex;
    gap: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 767px) {
        padding: 24px 0;
    }
}

.section-title {
    text-align: center;
    font-size: 32px !important;
    font-weight: 700 !important;
    line-height: 39px !important;
    @media (max-width: 767px) {
        font-size: 20px !important;
        font-weight: 600 !important;
        line-height: 22px !important;
    }

}
.section-title-mobile {
    text-align: center;
    font-size: 20px !important;
    font-weight: 600 !important;
    line-height: 22px !important;
    margin-bottom: 24px !important;
    @media (min-width: 768px) {
        display: none;
    }
}

.tiktok-swiper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}