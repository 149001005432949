.fullfiledAnswerBlock{
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;

  .iconContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.text{
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}

.btnContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.btn{
  height: 40px;
  padding: 0 16px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: none !important;
  background: #FDBA2F !important;
  color: #fff;
  box-sizing: border-box;
}