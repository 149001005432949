.graph-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &--row {
        display: grid;
        grid-template-columns: 3fr 2fr;
        align-items: center;
        max-width: 1200px;
        width: 100%;
        margin-top: 16px;
        margin-bottom: 40px;
    }
    .leftside {
        display: flex;
        justify-content: center;
    }

    .chart-container {
        width: 264px;
        height: 360px;
        margin-right: 72px;
    }

    .info {
        width: 100%;
        max-width: 348px;
        min-height: 360px;
        padding: 16px;
        text-align: center;
        box-sizing: border-box;
    }

    @media only screen and (max-width: 600px) {
        max-width: 100vw;
        padding: 0 16px;
        &--row {
            display: flex;
            max-width: 100vw;
            margin-bottom: 24px;
            justify-content: center;
        }
        .info {
            display: none;
        }
        .chart-container {
            margin-right: 0;
        }
    }
}
