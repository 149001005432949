@import '../../../static/styles/colors.scss';
.pay_in_form {
    max-width: 469px;
    //min-height: 586px;
    text-align: center;
    width: calc(100vw - 48px);

    &__toggle {
        width: 100%;
        background: transparent !important;
        border-radius: 8px !important;
        padding: 4px !important;
        margin-bottom: 12px;

        .MuiButtonBase-root {
            display: flex;
            text-transform: none !important;
            border: none !important;
            font-size: 16px;
            font-weight: 500;
            border-radius: 8px !important;
            padding: 16px 32px !important;
        }
    }
    &__contact_info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        text-align: left;
        margin-top: 24px;
        &__text {
            font-size: 14px;
            font-weight: 500;
            opacity: 0.65;
            margin-bottom: 4px;
        }
        &__text_bold {
            color: #0f2c52;
            text-decoration: none;
            font-size: 16px;
            font-weight: 600;
        }
    }
}

.loader {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 336px;
}
.loader__content {
    &__message {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 8px;
    }
}

.platform_toggle {
    width: 100%;
    background: transparent !important;
    border-radius: 8px !important;
    padding: 4px !important;
    //margin-bottom: 24px;
    //
    //background: transparent !important;
    //border-radius: 8px !important;
    //padding: 0px !important;
    //width: 100%;
    //margin-bottom: 16px;
    //gap: 8px;
}
.platform_toggle .MuiButtonBase-root {
    display: flex;
    text-transform: none !important;
    border: none !important;
    font-size: 16px;
    font-weight: 500;
    border-radius: 8px !important;
    padding: 16px 32px !important;
}
.platform_toggle .Mui-selected {
    background-color: $white !important;
    //padding: 8px 16px !important;
    color: $white !important;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}