@import '../../../static/styles/colors.scss';

.feedback_card {
    width: 356px;
    height: 166px;
    display: flex;
    align-items: center;
    padding: 12px;

    &__image {
        min-width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        //object-fit: cover;
        margin-right: 16px;

        & image {
            object-fit: cover;
            width: 100px;
            height: 100px;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__company {
        color: $yellow;
    }

    &__logo {
        height: 20px;
    }

    &__feedback {
        line-height: 15px;
        max-height: 80px;
        overflow: hidden;
        text-overflow: ellipsis; /* Show an ellipsis if text overflows */
    }

    &__line {
        display: flex;
        justify-content: space-between;
    }
}
