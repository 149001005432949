@import '../../../static/styles/colors.scss';
.company-card {
    display: flex;
    justify-content: center;
    align-items: start;
    background-color: transparent;
    width: 451px;
    padding: 0 24px;
    &--image {
        width: 40%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 24px;

        img {
            object-fit: cover;
        }
    }

    &--info {
        color: $blue;
        text-align: left;
        h3 {
            margin-bottom: 8px;
        }
    }

    @media screen and (max-width: 1000px) {
        width: 174px;
        height: 110px;
        padding: 0;
        &--image {
            width: 100%;
            height: 100%;
            margin-right: 0;

            & img{
                width: 100%;
                height: 100%;
                object-fit: contain;
                border-radius: 11px;

            }
        }
        &--info {
            display: none;
        }
    }
}
