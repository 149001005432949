@import "src/static/styles/colors";

.requirementBlock{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F5F7FF;
  padding: 40px 0;


  .wrapperBlock{
    max-width: 1120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    .titleHero{
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: $blue;
    }

    .infoContainer{
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      .partnersContainer{
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          transform: translateY(5px);

          @media screen and (max-width: 600px) {
            display: none;
          }

          .partnerCard{
            width: 268px;
            height: 117px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            gap: 6px;
            cursor: pointer;
          }

          .partnerTitle{
            font-size: 18px;
            font-weight: 500;
            line-height: 18px;
            color: $yellow !important;
          }
        }

      .descriptionsContainer{
        width: 100%;
        background: white;
        padding: 16px;
        border-radius: 8px;
        @media screen and (max-width: 600px) {
          width: 95%;
        }


        .descriptionContainer{
          width: 352px;
          min-height: 108px;
          padding: 24px;
          display: flex;
          gap: 16px;
          background: #fff;
          border-radius: 8px;
          border: 1px solid #ECECEF;
          box-sizing: border-box;
        }
      }
    }

  }
}

.titleAccordion{
  color: $blue;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;

  @media screen and (max-width: 600px) {
    color: $blue;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}

.activePartner{
  background: white;
  border-radius: 8px 8px 0 0;
}