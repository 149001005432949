@import '../../static/styles/colors.scss';
.infinite-loop {
    width: 100%;
    overflow: hidden;
    display: flex;

    & img {
        height: 48px;
        margin-right: 24px;
        //-webkit-filter: grayscale(1) invert(1);
        //filter: grayscale(1) invert(1);
    }
    &--anim-element {
        display: flex;
        justify-content: space-around;
        animation-name: marquee-infinite;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
    }
    & div {
        display: flex;
        justify-content: space-around;
    }

    @keyframes marquee-infinite {
        0% {
            transform: translateX(0%);
        }
        100% {
            transform: translateX(-50%);
        }
    }
}

.swiper-logos {
    width: 100%;
    padding: 0 24px;
    display: flex;
    max-width: 1180px;

    & button {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        padding: 0;
        margin: 12px;

        svg {
            color: $yellow;
        }

        &:hover svg {
            color: $blue;
        }

        @media (max-width: 600px) {
            display: none;
        }
    }

    & img {
        height: 48px;
        //margin-right: 24px;
        //-webkit-filter: invert(100%) sepia(100%) saturate(10000%) hue-rotate(180deg);
        //filter: invert(100%) sepia(100%) saturate(10000%) hue-rotate(180deg);
    }

    .swiper {
        flex-shrink: 2;
        width: 100%;
        height: 100%;
        //padding-top: 24px;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 48px !important;

        //box-shadow: 0 4px 40px $grey_shadow-block;
        border-radius: 16px;
        @media (max-width: 600px) {
            width: 172px !important;
        }
    }

    @media (max-width: 600px) {
        .mobile_bg {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 12px;
            width: 172px;
            height: 110px;
            background-color: $blue;
            border-radius: 16px;

            > img {
                height: 100%;
                -webkit-filter: brightness(0) invert(1);
                filter: brightness(0) invert(1);
            }
        }
        padding: 0;
    }

    //.swiper-slide img {
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //  border-radius: 16px;
    //}
    //.swiper-slide-visible {
    //    box-shadow: 0 1px 40px $grey_shadow-block !important;
    //}
    //.swiper-slide-active,
    //.swiper-slide-prev,
    //.swiper-slide-next {
    //    box-shadow: 0 4px 40px $grey_shadow-block !important;
    //}
}
