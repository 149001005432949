.buttons {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: fixed;
    right: 86px;
    bottom: 104px;
    z-index: 15;
    @media only screen and (max-width: 600px) {
        right: 15px;
        bottom: 17px;
    }
}
