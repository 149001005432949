@import '../../../static/styles/colors.scss';

.swiper-companies {
    width: 100%;
    display: flex;
    max-width: 1180px;
    button {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        padding: 0;
        svg {
            color: $yellow;
        }
        &:hover svg {
            color: $blue;
        }
        @media (max-width: 1000px) {
            display: none;
        }
    }
    .swiper {
        flex-shrink: 2;
        width: 100%;
        @media (max-width: 1000px) {
            padding: 36px;
        }
    }

    .swiper-slide {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        display: flex;
        justify-content: center;
        @media (max-width: 1000px) {
            width: 174px;
            height: 110px;
            display: block;
            border-radius: 11px;
        }
    }

    //.swiper-slide img {
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //  border-radius: 16px;
    //}
}
