@import '../../../static/styles/colors.scss';
.wrapper {
    width: 100%;
    background-color: $white;
    //border-radius: 60px 60px 0 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 100vh;
    z-index: 1;
    transition: 0.3s transform linear;
    overflow: hidden;
}

.wrapper-active {
    transform: translateY(-120px);
}

.logos-container {
    margin-top: 40px;
    max-width: 1180px;
    width: 100%;
}

.top {
    width: 100%;
    background-color: $grey;
    padding-bottom: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 700px) {
        padding-bottom: 0;
    }
}

.slider-container {
    position: relative;
    text-align: center;
    padding: 48px 0;
    max-width: 1200px;
    width: 100%;
    //height: 600px;
    box-sizing: border-box;
    @media screen and (max-width: 700px) {
        padding: 24px 0;
    }
}

.swiper-container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    background-color: $grey;
    padding: 48px 0;
    box-sizing: border-box;
}

.contacts-container {
    position: relative;
    padding: 48px 0;
    text-align: center;
    background-color: $grey;
    width: 100%;
    box-sizing: border-box;

    & > h2 {
        margin-bottom: 24px;
    }

    @media screen and (max-width: 600px) {
        padding: 24px 0;
    }
}

.feedback-container {
    background-color: $white;
    padding: 48px 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 700px) {
        padding: 24px 0;
    }
}

.anchor {
    position: absolute;
    top: -20px;
    left: 0;
}
