@import "src/static/styles/colors";


.contactBlock{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 40px 0;

  .wrapperBlock {
    max-width: 1120px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    .titleHero{
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: $blue;
    }

    .helperText{
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      color: $blue;
    }

    .contactsContainer{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      @media screen and (max-width: 600px) {
        padding: 0 16px;
        flex-direction: column;
        align-items: start;
        gap: 16px;
      }

      .contactContainer{
        display: flex;
        align-items: center;
        gap: 16px;

        .descriptionContainer{
          display: flex;
          flex-direction: column;
          justify-content: start;
          gap: 8px;

          .descriptionTitle{
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            color: $blue;
          }

          .descriptionLink{
            text-decoration: none;
            font-size: 16px;
            font-weight: 500;
            line-height: 20px;
            color: $blue;
          }
        }
      }
    }
  }
}