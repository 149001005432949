.feedback_form {
    max-width: 452px;
    text-align: center;
    width: calc(100vw - 48px);

    h2 {
        margin-bottom: 24px;
    }

    .form-container {
        display: flex;
        flex-direction: column;
        position: relative;
        width: calc(100% + 4px);

        .MuiOutlinedInput-root {
            padding: 0;
        }

        .MuiFormControl-root {
            margin: 4px 0 4px 4px !important;
        }
    }

    .text-area-input-root {
        width: 100%;
    }

    .text-area-input {
        padding-right: 50px !important;
    }

    .text-area-icon {
        position: absolute;
        top: 32px;
        right: 16px;
        transform: translateY(-50%);
        color: #fdba2f;
    }
}
