//import "../../../static/styles/colors.scss"
//@import 'static/styles/nulllstyle.scss';
@import '../../../static/styles/colors.scss';
//@import 'static/styles/fonts.scss';

.form-box {
    width: 452px;
    gap: 16px;
    margin-left: 56px;
    @media (max-width: 767px) {
      width: 100%;
      margin-left: 0;
    }
}

.standard_toggle_tiktok {
    background: transparent !important;
    border-radius: 8px !important;
    padding: 0px !important;
    width: 100%;
    margin-bottom: 16px;
    gap: 8px;
}
.standard_toggle_tiktok .MuiButtonBase-root {
  width: 100%;
  text-transform: none !important;
  border: none !important;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px !important;
  color: $blue !important;
  line-height: 19.5px;
  padding: 16px 0 !important;
  @media (max-width: 523px) {
    padding: 16px 12px !important;
  }
}
.standard_toggle_tiktok .Mui-selected {
  background-color: $yellow !important;
  //padding: 8px 16px !important;
  color: $white !important;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
}
.form_field {
  margin: 8px 0 !important;
  &:first-child {
    margin-top: 16px !important;
  }
  &:last-child {
    margin-bottom: 0 !important;
  }

  @media screen and (max-width: 700px) {
    margin: 8px 0 !important;
  }

  label {
    color: $blue;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-family: 'Montserrat';

    &.Mui-focused {
      color: $blue;
    }
  }
  .MuiOutlinedInput-root {
    border-radius: 8px;
    background-color: $grey;

    .MuiOutlinedInput-input {
      padding: 19.5px 16px;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      font-family: 'Montserrat';
    }

    .MuiInputLabel-outlined {
      left: 14px;
      top: 14px;
      padding: 0px 4px;
    }

    .MuiOutlinedInput-notchedOutline {
      border-color: transparent;
    }

    &:hover .MuiOutlinedInput-notchedOutline {
      border-color: $yellow;
    }
    &.Mui-disabled {
      color: #0f2c52;
      border-color: transparent;
    }
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: $yellow;
      color: $blue;
    }
  }

  .MuiFormHelperText-root {
    margin-top: 8px;
    font-size: 14px;
    color: #f44336;
  }
}
.button-filled-tiktok {
  background: $yellow !important;
  border-radius: 8px !important;
  padding: 18px 24px !important;
  text-transform: none !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  /* identical to box height */
  text-align: center;
  /* TDS/Белый */
  color: #ffffff !important;
  transition: transform 0.3s;

  &:hover {
    transform: scale(1.01);
  }
  @media (max-width: 767px) {
    width: 100%;
  }
}

.button-filled-tiktok:disabled {
  background: gray !important;
}

.error-message {
  margin-left: 16px;
  color: red;
  margin-top: -8px;
  font-size: 12px;
}