a,
p,
h1,
h2,
h3,
h4,
input,
span,
button {
    font-family: 'Montserrat', sans-serif !important;
}
.font-48 {
    font-size: 48px;
    line-height: 58px;
    @media screen and (max-width: 600px) {
        font-size: 24px;
        line-height: 28px;
    }
}
.font-40 {
    font-size: 40px;
    line-height: 49px;
}
.font-32 {
    font-size: 32px;
    line-height: 42px;
    @media screen and (max-width: 600px) {
        font-size: 20px;
        line-height: 22px;
    }
}
.font-24 {
    font-size: 24px;
    line-height: 29px;
}
.font-20 {
    font-size: 20px;
    line-height: 24px;
}
.font-16 {
    font-size: 16px;
    line-height: 20px;
    @media screen and (max-width: 600px) {
        font-size: 14px;
        line-height: 17px;
    }
}
.font-14 {
    font-size: 14px;
    line-height: 17px;
}
.font-12 {
    font-size: 12px;
    line-height: 20px;
}
.font-10 {
    font-size: 10px;
    line-height: 14px;
}

.w300 {
    font-weight: 300;
}
.w400 {
    font-weight: 400;
}
.w500 {
    font-weight: 500;
}
.w600 {
    font-weight: 600;
}
.w700 {
    font-weight: 700;
    @media screen and (max-width: 600px) {
        font-weight: 600;
    }
}
.w900 {
    font-weight: 900;
}
