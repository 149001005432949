@import "src/static/styles/colors";

.textField{
  width: 100%;
  max-width: 320px;
  padding: 12px;
  background: #fff;
  border: 1px solid #ECECEF;
  border-radius: 4px;
  color: #666666;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;

  &:focus {
    outline: 1px solid $yellow !important;
  }
  &:hover{
    border-color: $yellow !important;
  }
}