@import '../../../static/styles/colors.scss';
.person-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    background-color: $white;
    width: 160px;
    height: 160px;
    border-radius: 16px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;

    //background-color: #00ddff;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    &--info {
        width: 100%;
        position: absolute;
        bottom: 0;
        min-height: 46px;
        padding: 8px;
        background: rgba(255, 255, 255, 0.8);
        text-align: center;
    }
    @media (max-width: 600px) {
        width: 200px;
        height: 200px;
    }
}
