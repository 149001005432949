.rejectAnswerBlock{
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
  justify-content: center;
  height: 240px;

  .iconContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
  }
}

.text{
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
}