@import '../../../../static/styles/colors.scss';

.open_feedback_card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;

    &__row {
        display: flex;
        gap: 8px;
        width: 100%;
    }
    &__avatar {
        position: relative;
        width: 120px;
        height: 120px;
    }
    &__linkedin {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 20px;
        height: 20px;
    }
    &__image {
        width: 120px;
        height: 120px;
        border-radius: 50%;
        overflow: hidden;
        //object-fit: cover;
        margin-right: 16px;

        & img {
            object-fit: cover;
            min-width: 120px;
            height: 120px;
        }
    }

    &__pseudoinput {
        width: 100%;
        display: flex;
        padding: 18px;
        justify-content: space-between;
        background-color: $grey;
        > p {
            line-height: 1.5;
        }
    }

    &__info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        width: 100%;
    }

    &__company {
        color: $yellow;
    }

    &__logo {
        height: 20px;
    }

    &__feedback {
        line-height: 15px;
        max-height: 80px;
        overflow: hidden;
        text-overflow: ellipsis; /* Show an ellipsis if text overflows */
    }

    &__line {
        display: flex;
        justify-content: space-between;
    }
}
