.first_page {
    padding: 24px 0;
    width: 100%;
    box-sizing: border-box;
}
.end_form {
    padding: 24px 0;
    text-align: left;
}
.mobile_service {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 0 16px;
}
