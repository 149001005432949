@import 'static/styles/nulllstyle.scss';
@import 'static/styles/colors.scss';
@import 'static/styles/fonts.scss';

html {
    scroll-behavior: smooth;
    background-color: $blue;
}

body {
    margin: 0;
    padding: 0;
    //background-color: #000b16;
    color: $blue;
    outline-color: $yellow;
}

.main-container {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 12px;
    text-align: left;
    box-sizing: border-box;
}

.MuiList-root{
    max-height: 300px;
}

.standard_toggle {
    background: transparent !important;
    border-radius: 8px !important;
    padding: 4px !important;
    gap: 8px;
    justify-content: center;

    .MuiButtonBase-root {
        text-transform: none !important;
        border: none !important;
        font-size: 16px;
        font-weight: 500;
        border-radius: 8px !important;
        color: $blue !important;
        line-height: 19.5px;
        padding: 16px 32px !important;
    }

    .Mui-selected {
        background-color: $yellow !important;
        //padding: 8px 16px !important;
        color: $white !important;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
    }
}

.exclusive_toggle {
    background: transparent !important;
    border-radius: 8px !important;
    padding: 4px !important;
    gap: 12px;

    .MuiButtonBase-root {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        text-transform: none !important;
        border: none !important;
        border-radius: 8px !important;
        color: $blue !important;
        padding: 12px 32px !important;
        min-height: 64px;
    }

    .Mui-selected {
        border-radius: 8px !important;
        background-color: $white !important;
        border: 2px solid $yellow !important;
        box-shadow: 0px 0px 12px rgba(255, 187, 13, 0.5);
    }
}

.exclusive_toggle_mobile {
    background: transparent !important;
    border-radius: 8px !important;
    padding: 4px !important;
    gap: 16px;

    .MuiButtonBase-root {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
        text-transform: none !important;
        border: none !important;
        border-radius: 8px !important;
        color: $blue !important;
        min-height: 50px;
        box-sizing: border-box;
    }

    .Mui-selected {
        background-color: $white !important;
        border: 2px solid $yellow !important;
        box-shadow: 0px 0px 12px rgba(255, 187, 13, 0.5);
    }
}

.blue-span {
    color: $light-blue;
}

.yellow-span {
    color: $yellow;
}

.button-filled {
    background: $yellow !important;
    border-radius: 8px !important;
    padding: 16px 32px !important;
    text-transform: none !important;
    text-decoration: none;

    /* Montserrat/16px/Medium */
    font-style: normal;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    /* identical to box height */
    text-align: center;
    /* TDS/Белый */
    color: #ffffff !important;
    transition: transform 0.3s;

    &:hover {
        transform: scale(1.01);
    }
}

.button-filled:disabled {
    background: gray !important;
}

.separator {
    width: 100%;
    border: 1px solid $yellow;
    background-color: $yellow;
}

.separator-black {
    border: 1px solid $black;
    background-color: $black;
    width: 100%;
}

.main-table {
    border-collapse: collapse;
    width: 100%;

    th,
    td {
        color: #0f2c52;
        text-align: right;
        font-family: 'Montserrat';
        font-style: normal;
        padding: 4px 8px;

        &:first-child {
            text-align: left;
        }
    }

    thead th {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
    }

    tbody {
        & th,
        & tr {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
        }

        & th {
            min-width: 128px;
        }
    }
}

.table-centred {
    th,
    td {
        text-align: center;

        &:last-child {
            text-align: left;
        }
    }
}

.no_broke {
    white-space: nowrap;
}

.form_field {
    margin: 4px 0 !important;
    &:first-child {
        margin-top: 0 !important;
    }
    &:last-child {
        margin-bottom: 0 !important;
    }

    @media screen and (max-width: 700px) {
        margin: 8px 0 !important;
    }

    label {
        color: $blue;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        font-family: 'Montserrat';

        &.Mui-focused {
            color: $blue;
        }
    }
    .MuiOutlinedInput-root {
        border-radius: 8px;
        background-color: $grey;

        .MuiOutlinedInput-input {
            padding: 19.5px 16px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
        }

        .MuiInputLabel-outlined {
            left: 14px;
            top: 14px;
            padding: 0px 4px;
        }

        .MuiOutlinedInput-notchedOutline {
            border-color: transparent;
        }

        &:hover .MuiOutlinedInput-notchedOutline {
            border-color: $yellow;
        }
        &.Mui-disabled {
            color: #0f2c52;
            border-color: transparent;
        }
        &.Mui-focused .MuiOutlinedInput-notchedOutline {
            border-color: $yellow;
            color: $blue;
        }
    }

    .MuiFormHelperText-root {
        margin-top: 8px;
        font-size: 14px;
        color: #f44336;
    }
}

h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 22px;
}

.default_checkbox {
    .MuiTypography-root {
        padding-top: 9px;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        text-align: start;
        @media screen and (max-width: 700px) {
            white-space: normal;
            word-break: break-word;
        }
    }
}


.standard_accordion {
    width: 100%;
    box-shadow: none !important;
    margin: 0 !important;

    border-bottom: 2px solid $yellow;
    border-radius: 0 !important;

    &:before {
        display: none;
    }
    &:first-child {
        border-top: 2px solid $yellow;
    }
    .MuiAccordionSummary-content {
        margin: 16px 0 !important;
    }

    .MuiAccordionSummary-root {
        min-height: initial !important;
        padding: 0 !important;
        text-align: left;
        color: $blue;
    }
    .MuiAccordionDetails-root {
        padding: 0 !important;
    }
}

.custom_select {
    .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }
    .MuiSelect-select {
        padding: 16px 12px;
        min-height: 0 !important;
        font-family: 'Montserrat';
    }
}

.tooltip {
    cursor: pointer;
    position: relative;
    &:hover .tooltip-text {
        visibility: visible;
    }
    .tooltip-text-left {
        top: 5px;
        right: 120%;
        &::before {
            top: 35%;
            left: 94%;
        }
    }

    .tooltip-text {
        visibility: hidden;
        position: absolute;
        z-index: 2;
        color: white;
        font-size: 12px;
        background-color: #192733;
        border-radius: 4px;
        padding: 10px 15px 10px 15px;
        white-space: nowrap;
        &::before {
            content: '';
            position: absolute;
            transform: rotate(45deg);
            background-color: #192733;
            padding: 5px;
            z-index: 1;
        }
    }
}

input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.accordionIndexCardRefillPage{
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    color:rgba(0, 45, 85, 0.2);
}

.accordionTextRefillPage{
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;

    b{
        font-weight: 500;
    }

    a{
        color: rgba(0, 135, 255, 1);
        text-decoration: underline;
    }
}