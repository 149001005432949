@import "src/static/styles/colors";

.createAccBlock{
  max-width: 656px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 24px;

  .title{
    color: #002D55;
    font-size: 20px;
    font-weight: 500;
    line-height: 25px;
  }

  .inputContainer{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 16px;

    @media screen and (max-width: 600px) {
      flex-direction: column;
    }
  }

  .loadFileContainer{
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 16px;

    .loadFileBtn{
      height: 48px;
      min-width: 48px;
      padding: 12px;
      background: $yellow;
      border: none;
      border-radius: 4px;

      .inputFile{
        width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
      }
    }

    .loadFileTextContainer{
      max-width: 256px;
      display: flex;
      flex-direction: column;
      justify-content: start;
      gap: 4px;

      .loadFileText{

        color: #666;
        font-size: 14px;
        font-weight: 400;
        line-height: 16px;
      }
      .fileName{
        color: #666;
        font-size: 12px;
        font-weight: 400;
        line-height: 16px;
      }
    }
  }

  .btnContainer{
    width: 100%;
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: center;

    .btn{
      height: 40px;
      background: $yellow;
      padding: 8px 16px;
      border: none;
      border-radius: 4px;
      color: #fff !important;
    }
  }

}

.errorMessage{
  color: red;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}