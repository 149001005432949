@import '../../../static/styles/colors.scss';

.swiper-feedback {
    width: 100%;
    display: flex;
    max-width: 1180px;
    margin-top: 28px;

    button {
        flex-shrink: 0;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        border: none;
        background-color: transparent;
        padding: 0;

        svg {
            color: $yellow;
        }

        &:hover svg {
            color: $blue;
        }
    }

    .swiper {
        flex-shrink: 2;
        width: 100%;
    }

    .swiper-slide {
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;
        //@media (max-width: 700px) {
        //    width: 328px;
        //    height: 166px;
        //}
    }

    //.swiper-slide img {
    //  display: block;
    //  width: 100%;
    //  height: 100%;
    //  object-fit: cover;
    //  border-radius: 16px;
    //}
}
