@import "src/static/styles/colors";


.aboutUsBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(245, 247, 255, 1);
  padding: 40px 0;

  .wrapperBlock {
    max-width: 1120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    @media screen and (max-width: 600px) {
      overflow: hidden;
    }

    .titleHero{
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: $blue;
    }
    .descriptionContainer{
      width: 100%;
      max-width: 700px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;

      @media screen and (max-width: 600px) {
        flex-direction: column;
      }

      .descriptionText{
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        color: $blue;

        @media screen and (max-width: 600px) {
          text-align: center;
          padding: 0 16px;
        }
      }
    }
    .footerText{
      font-size: 20px;
      font-weight: 500;
      line-height: 24px;
      color: $blue;
    }
  }
}