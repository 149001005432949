@import "src/static/styles/colors";


.faqBlock{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  padding: 40px 0;
  @media screen and (max-width: 600px) {
    padding: 40px 16px;
  }

  .wrapperBlock{
    max-width: 1120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    .titleHero{
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: $blue;
    }

    .faqContainer{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;
    }

  }

}

.titleAccordion{
  color: $blue;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}