@import '../../static/styles/colors.scss';

.footer {
    width: 100%;
    padding: 24px;
    display: flex;
    justify-content: center;
    background-color: $blue;
    color: $white;
    
    &__div {
        width: 100%;
        max-width: 1120px;
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        align-self: stretch;
        
        @media (max-width: 800px) {
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
    }
    .copyright_text {
        font-size: 14px;
        font-weight: 500;
        line-height: 16px;
        align-items: center;
    }
    &__contacts {
        display: flex;
        gap: 24px;
    }

    &__contact {
        display: flex;

        & img {
            width: 32px;
            height: 32px;
        }
    }

    @media (max-width: 800px) {
        padding: 16px 24px;
        &__div {
            flex-direction: column;
            justify-content: center;
            gap: 16px;
        }
        &__contacts {
            margin-top: 16px;
        }
    }
}

.offerts_block {
    display: flex;
    gap: 24px;
    align-items: center;
    @media (max-width: 800px) {
        flex-direction: column;
        gap: 16px;
        justify-content: center;
    }
}
.payment {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;

}
.offert_link {
    text-decoration: none;
    color: $white;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
}

.offert_link:hover {
    text-decoration: underline;
}

.payment_icons {
    display: flex;
    gap: 10px;
    align-items: center;
}