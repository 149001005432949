.fullscreen {
    min-height: 100vh;
    width: 100%;
    position: relative;
    right: 0;
    bottom: 0;
    z-index: 0;
    overflow: hidden;
    opacity: 0;
}

#backgroundVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100vw;
    min-height: 100vh;
    filter: brightness(0.5);
    @media screen and (max-width: 600px) {
        top: 0;
        bottom: initial;
    }
}

.content {
    position: absolute;
    z-index: 1;
    min-width: 100vw;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    .logo {
        height: 300px;
        margin: 0 auto;
        //margin-top: 132px;
        transition: 0.3s height linear;
    }

    .logo-active {
        height: 200px;
    }

    button {
        margin: 0 auto;
    }
    @media screen and (max-width: 600px) {
        .logo {
            height: 138px;
            margin-top: 0;
        }
    }
}
.mobile-br {
    display: none;
    @media screen and (max-width: 600px) {
        display: inline;
    }
}
