@import "src/static/styles/colors";

.calculatorBlock{
  width: 100%;
  background: rgba(245, 247, 255, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-bottom: 16px;

  .wrapperBlock{
    max-width: 1120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;

    .titleHero{
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      color: $blue;
    }
    .companyContainer{
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      gap: 16px;

      .companyCard{
        width: 171px;
        height: 171px;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 8px;
        box-sizing: border-box;
        cursor: pointer;
      }
    }

    .calculatorContainer{
      max-width: 732px;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 16px;
      flex-wrap: wrap;


      .customInput{
        width: 358px;
        max-width: 358px;
        background:white;
      }

    }

    .calcHelperText{
      max-width: 732px;
      font-size: 15px;
      font-weight: 400;
      line-height: 20px;
      color: $blue;
      text-align: center;
    }

    .calculateBtn{
      width: 100%;
      max-width: 119px;
      height: 40px;
      background: $yellow;
      border: none;
      border-radius: 4px;
      color: #fff !important;
      text-transform: none !important;
      cursor: pointer;
    }
  }
}

.activeCard{
  border: 1px solid $yellow;
}

.activeText{
  color: #666666 !important;
}

