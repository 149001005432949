.headerRefill{
  width: 100%;
  height: 72px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 9999;
  padding: 0 16px;

  .headerContainer{
    width: 100%;
    max-width: 1120px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .containerBtn{
      display: flex;
      align-items: center;
      gap: 8px;

      .titleBtn{
        font-size: 16px;
        font-weight: 500;
        line-height: 18px;
      }

      .headerBtn{
        height: 40px;
        padding: 0 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: none !important;
      }

      .createAccBtn{
        background: #FDBA2F;
        color: #fff;
      }
      .refillAccBtn{
        background: #fff;
        color: #FDBA2F;
        box-sizing: border-box;
        border: 1px solid #FDBA2F;

        .visibleWord{
          @media screen and (max-width: 600px) {
            display: none;
          }
        }
      }
    }
  }
}