@import "src/static/styles/colors";

.swiperButtons {
  width: 100%;
  max-width: 100vw;
  display: none;
  margin-top: 28px;
  padding: 16px;

  @media screen and (max-width: 600px) {
    display: flex;
  }

  button {
    flex-shrink: 0;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    background-color: transparent;
    padding: 0;

    svg {
      color: $yellow;
    }
  }
}

.card{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  span{
    color: $yellow;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
}


.btnL{
  margin-left: 12px;
}
.btnR{
  margin-right: 12px;
}